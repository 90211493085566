import {FC, useEffect, useState} from 'react';
import {useQuery} from '@apollo/react-hooks';
import jwt_decode from 'jwt-decode';
import {GET_ACCESS_TOKEN, ME} from '../../graphql/queries';
import {setFreshChatUserData} from '../../helpers/trackingEvents';
import useHasFocus from '../../hooks/useHasFocus';
import {UserWrapProps} from '../../types';
import {
  setUserEmail,
  setUserName,
  sendUserDataToExtension,
  setUserToken,
  getRawCookie,
  getUserToken,
} from '../../helpers';

const UserWrap: FC<UserWrapProps> = ({withUser, withoutUser, withLoading}) => {
  const [updatedToken, setUpdatedToken] = useState('');
  const [tokenExpDate, setTokenExpDate] = useState(900000);
  const focus = useHasFocus();
  const {data, loading, refetch} = useQuery(ME);
  const {data: getAccessTokenData, refetch: refetchAccessToken} = useQuery(GET_ACCESS_TOKEN, {
    pollInterval: tokenExpDate,
    skip: !getRawCookie('ref_token') || !getRawCookie('device_key'),
    variables: {
      settings: {
        refresh_token: getRawCookie('ref_token'),
        device_key: getRawCookie('device_key'),
        product_type: 'hexomatic',
      },
    },
  });
  useEffect(() => {
    focus && refetchAccessToken();
  }, [focus]);

  useEffect(() => {
    if (getAccessTokenData && getAccessTokenData.User && getAccessTokenData.User.getAccessToken) {
      if (getAccessTokenData.User.getAccessToken.token) {
        const jwtParsed = jwt_decode(getAccessTokenData.User.getAccessToken.token);
        //@ts-ignore
        const expDate = new Date((jwtParsed.exp || 0) * 1000) - new Date() - 55000;
        setTokenExpDate(expDate);
      }
      setUpdatedToken(getAccessTokenData.User.getAccessToken.token);
      setUserToken(getAccessTokenData.User.getAccessToken.token);

      sendUserDataToExtension(
        getAccessTokenData.User.getAccessToken.token,
        getRawCookie('ref_token'),
        getRawCookie('device_key'),
      );
    }
  }, [getAccessTokenData]);

  // @ts-ignore
  setFreshChatUserData(data && data.User && data.User.get);

  useEffect(() => {
    if (updatedToken === getUserToken()) {
      refetch();
    }
  }, [updatedToken, getUserToken()]);

  useEffect(() => {
    if (data && data.User && data.User.get && !data.User.get.error) {
      setUserEmail(data.User.get.email);
      setUserName(data.User.get.firstName);
      return;
    }
    sendUserDataToExtension();
  }, [data && data.User && data.User.get]);

  if (loading && withLoading) {
    return withLoading();
  }

  if (data && data.User && data.User.get && !data.User.get.error) {
    return withUser(data.User.get);
  }

  return withoutUser();
};

export default UserWrap;
