import React, {FunctionComponent, useRef, useState, useEffect} from 'react';
import ReactTooltip from 'react-tooltip';
import {useMediaQuery} from 'react-responsive';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Link} from 'gatsby';

import {HeaderMenuItemProps} from '../../../types';
import {getUserName} from '../../../helpers';

import './styles.scss';

const HeaderMenuItem: FunctionComponent<HeaderMenuItemProps> = ({
  to,
  name,
  target,
  className,
  icon,
  img,
  src,
  func,
}) => {
  const refName = useRef<HTMLParagraphElement | null>(null);
  const [checkWidth, setCheckWidth] = useState(false);
  const isMobile = useMediaQuery({maxWidth: 991});
  if (name === 'Academy') {
    return (
      <a
        href={to}
        onClick={e => isMobile && e.preventDefault()}
        className={`dropdown-toggle ${className}`}
        target={target}
        no-index
      >
        {icon && <FontAwesomeIcon icon={icon} className="user-icon" />}
        {img && (
          <img alt={getUserName()} src={src} className="gravatar-img" style={{borderRadius: '50%', width: '35px'}} />
        )}
        {name}
      </a>
    );
  }

  useEffect(() => {
    if (name && refName.current) {
      setCheckWidth(refName?.current?.scrollWidth !== refName?.current?.offsetWidth);
    }
  }, [name, refName.current]);

  return to && to.indexOf('://') > -1 ? (
    <a href={to} onClick={func} className={`dropdown-toggle ${className}`} target={target} no-index>
      {icon && <FontAwesomeIcon icon={icon} className="user-icon" />}
      {img && (
        <img alt={getUserName()} src={src} className="gravatar-img" style={{borderRadius: '50%', width: '35px'}} />
      )}
      {name}
    </a>
  ) : (
    <>
      <ReactTooltip place="bottom" className="tooltip" />

      {!func ? (
        <Link
          to={to || ''}
          target={target}
          onClick={func}
          className={`dropdown-toggle ${className}`}
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          state={to && to === '/login' ? {goBack: true} : {goBack: false}}
        >
          {icon && <FontAwesomeIcon icon={icon} className="user-icon" />}
          {img && <img alt={getUserName()} src={src} className="gravatar-img" style={{borderRadius: '50%'}} />}
          {name}
        </Link>
      ) : (
        <span
          ref={refName}
          data-tip={checkWidth ? name : ''}
          onClick={func}
          className={`dropdown-toggle text-truncate user-name ${className}`}
          role="button"
        >
          {icon && <FontAwesomeIcon icon={icon} className="user-icon" />}
          {img && <img alt={getUserName()} src={src} className="gravatar-img" style={{borderRadius: '50%'}} />}
          {name}
        </span>
      )}
    </>
  );
};

export default HeaderMenuItem;
