import React, {FunctionComponent} from 'react';
import {Link} from 'gatsby';
import {HeaderMenuItemProps} from '../../../types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const HeaderMenuSubItem: FunctionComponent<HeaderMenuItemProps> = ({to, name, icon, func, className}) => (
  <li className="nav-item">
    {name !== 'Sign out' ? (
      <>
        {to && to.indexOf('://') > -1 && name !== 'News' && name !== 'Tutorials' ? (
          <a href={to} onClick={func} className={`dropdown-toggleasd ${className}`} no-index>
            {icon && <FontAwesomeIcon icon={icon} className="user-icon" />}
            {name}
          </a>
        ) : (
          <Link
            to={to || ''}
            state={to && to === '/login' ? {goBack: true} : {goBack: false}}
            onClick={func}
            className="nav-link"
            activeClassName="active"
          >
            {icon && <FontAwesomeIcon icon={icon} className="user-icon" />}
            {name}
          </Link>
        )}
      </>
    ) : (
      <>
        {to && to.indexOf('://') > -1 ? (
          <button onClick={func} className={`sign-out-btn btn dropdown-toggle ${className}`}>
            {icon && <FontAwesomeIcon icon={icon} className="user-icon" />}
            {name}
          </button>
        ) : (
          <button onClick={func} className="sign-out-btn btn nav-link">
            {icon && <FontAwesomeIcon icon={icon} className="user-icon" />}
            {name}
          </button>
        )}
      </>
    )}
  </li>
);

export default HeaderMenuSubItem;
