import React, { FC, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faTimes } from "@fortawesome/free-solid-svg-icons";
import SubscriptionContent from "./subscription-content";
import classnames from "classnames";
import { setShowModalonExit } from "../../helpers";
import "./styles.scss";

const SubscriptionModal: FC<{
  isModalOpen: boolean;
  handleSetShow?: (arg: boolean) => void;
}> = ({ isModalOpen, handleSetShow }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsOpen(isModalOpen);
    isModalOpen && document.body.classList.add("overflow-hidden");
  }, [isModalOpen]);

  const handleModalClose = () => {
    setShowModalonExit("false");
    setIsOpen(false);
    handleSetShow && handleSetShow(false);
    document.body.classList.remove("overflow-hidden");
  };

  return (
    <div className={classnames("modal-magnet", { "d-none": !isOpen })}>
      {loading && (
        <FontAwesomeIcon icon={faCircleNotch} spin className="spin" size="3x" />
      )}
      <div
        className={classnames("modal-magnet-content", {
          "modal-blur": loading,
        })}
      >
        <div className="modal-close">
          <FontAwesomeIcon icon={faTimes} onClick={handleModalClose} />
        </div>
        <div className="modal-body">
          <SubscriptionContent setLoading={setLoading} modal />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionModal;
