import React, { FC, useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import classnames from "classnames";
import { MAGNET_SUBSCRIBE } from "../../graphql/mutations";
import { setShowModalonExit } from "../../helpers";
import pdfPlaybook from "../../images/pdf-playbook.png";
import pdfPlaybookLarge from "../../images/pdf-playbook-large.png";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

interface IContent {
  setLoading?: (val: boolean) => void;
  modal?: boolean;
}
const SubscriptionContent: FC<IContent> = ({ setLoading, modal }) => {
  const [subscribe, { loading }] = useMutation(MAGNET_SUBSCRIBE);

  const [email, setEmail] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [checkboxError, setCheckboxError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubscribe = async () => {
    setEmailError("");
    if (!termsAccepted) {
      setCheckboxError(
        modal
          ? "Please accept the Terms and the Privacy Policy to continue"
          : "Please agree to receiving periodic updates"
      );
    } else {
      const { data } = await subscribe({
        variables: {
          email: email,
          type: "GROWTH_HACKING",
          comment: "The growth hacking playbook",
        },
      });
      const { error, message } =
        data &&
        data.WatchSubscribeOps &&
        data.WatchSubscribeOps.launchSubscribe;
      if (error) {
        setEmailError(message);
      } else {
        setEmail("");
        setSuccess(true);
        setShowModalonExit("false");
      }
    }
  };

  useEffect(() => {
    setLoading && setLoading(loading);
  }, [loading]);
  return (
    <>
      {!success && modal && (
        <p className='modal-text'>Get our best growth hacking and automation strategies</p>
      )}
      {success && (
        <>
          <p className="success-modal-text">Thank you</p>

          <p className="success-modal-text">
            Please check your inbox to confirm your email and download your
            playbook.
          </p>
        </>
      )}

      <img
        src={modal ? pdfPlaybook : pdfPlaybookLarge}
        alt="Scale and automate"
      />

      {!success && (
        <>
          <input
            value={email}
            type="email"
            name="email"
            onChange={(e) => {
              setEmailError("");
              setEmail(e.target.value);
            }}
            style={{ borderRadius: "6px" }}
            placeholder={modal ? "Enter your email here" : "Email"}
            className={classnames("subscribe-email", {
              "error-border": emailError,
            })}
          />
          <label className="subscribe-label">
            <div
              className={classnames("subscribe-checkbox", {
                "error-border": checkboxError,
              })}
            >
              {termsAccepted && <div className="selected"></div>}
              <input
                type="checkbox"
                required
                checked={termsAccepted}
                onChange={() => {
                  setCheckboxError("");
                  setTermsAccepted(!termsAccepted);
                }}
              />
            </div>
            {modal ? (
              <>
                I accept{" "}
                <a href="https://hexomatic.com/terms-of-use" target="_blank" aria-label={'https://hexomatic.com/terms-of-use'}>
                  the Terms
                </a>{" "}
                and{" "}
                <a href="https://hexomatic.com/privacy-policy" target="_blank" aria-label={'https://hexomatic.com/privacy-policy'}>
                  the Privacy Policy
                </a>
              </>
            ) : (
              <span>
                I agree to receiving periodic updates about Hexomatic and when
                we update the growth hacking playbook
              </span>
            )}
          </label>
          <button
            onClick={handleSubscribe}
            type="button"
            className="btn subscribe-btn"
          >
            {modal ? (
              "Access the growth hacking playbook"
            ) : (
              <>
                Get access{" "}
                {loading && (
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    spin
                    className="spin"
                    size="1x"
                  />
                )}
              </>
            )}
          </button>
          {(emailError || checkboxError) && (
            <p className="error-message">{emailError || checkboxError}</p>
          )}
        </>
      )}
    </>
  );
};

export default SubscriptionContent;
